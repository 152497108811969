.InternetExplorer {
  .WindowExplorer__view {
    position: relative;
    overflow: hidden;
    display: initial;
    margin: 2px;
    height: 0px; // @todo WHAT?!
  }

  iframe.crossOrigin {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    left: -50%;
    top: -50%;
    position: absolute;
  }
}
