@import "../../../node_modules/packard-belle/src/_scss/w98/mixins/box-shadows.scss";

@font-face {
  font-family: pixelmix;
  src: url("../../assets/pixelmix.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: FixedSys;
  src: url("../../assets/FixedsysStripped.woff2") format("woff2");
}

.JSDos {
  input {
    filter: opacity(0%);
    position: absolute;
    left: -2000px;
  }
  .terminal {
    @include shadow-input;
    flex-grow: 1;
    * {
      font-family: pixelmix, FixedSys, MSSansSerif, "Courier New", Courier,
        monospace;
    }
    background-color: black;
    color: white;
    font-size: 8px;
    letter-spacing: 1px;
    line-height: 1.5em;
    padding: 8px 4px;
    overflow: auto;
    &::selection {
      background-color: white;
      color: black;
    }
  }
  form:focus-within + .terminal .terminal__content > span:after {
    content: "_";
  }
  &.Window:after {
    content: none;
  }
}
