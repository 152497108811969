.react-draggable {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 7;
  /* transition: 500ms height ease-in, 500ms width ease-in; */
}
.react-resizable-handle {
  height: 15px;
  width: 15px;
  z-index: 1;
  position: absolute;
  right: -3px;

  bottom: -3px;
}

.WindowExplorer {
  width: 100%;
  height: 100%;

  &.wait2,
  &.wait {
    * {
      pointer-events: none;
    }

    footer:after {
      width: 54px;
      background-position: right;
      background-repeat: no-repeat;
      content: 'Loading...';
    }
  }

  &__view {
    display: flex;
  }

  // &.Window--maximized {
  //   width: calc(100% + 4px);
  //   height: calc(100% + 4px);
  // }
}

.WindowAlert {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
}

.StandardMenu {
  z-index: 1;
}
