@import "../../../node_modules/packard-belle/src/_scss/w98/mixins/box-shadows.scss";

@font-face {
  font-family: "FixedSys";
  src: url("../../assets/FixedsysStripped.woff2") format("woff2");
}

.Window--blocked * {
  pointer-events: none !important;
  user-select: none;
}

.Minesweeper {
  width: initial !important;
  height: initial !important;
}
