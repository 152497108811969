.activeDesktop16 {
  background-image: url(./activeDesktop16-min.png);
}
.addressBook16 {
  background-image: url(./addressBook16-min.png);
}
.calculator16 {
  background-image: url(./calculator16-min.png);
}
.computer32 {
  background-image: url(./computer32-min.png);
}
.controlPanel16 {
  background-image: url(./controlPanel16-min.png);
}
.controlPanel32 {
  background-image: url(./controlPanel32-min.png);
}
.find24 {
  background-image: url(./find24-min.png);
}
.findComputer16 {
  background-image: url(./findComputer16-min.png);
}
.findFiles16 {
  background-image: url(./findFiles16-min.png);
}
.findOnline16 {
  background-image: url(./findOnline16-min.png);
}
.findPeople16 {
  background-image: url(./findPeople16-min.png);
}
.folder16 {
  background-image: url(./folder16-min.png);
}
.folder32 {
  background-image: url(./folder32-min.png);
}
.folderFavorites24 {
  background-image: url(./folderFavorites24-min.png);
}
.folderOpen24 {
  background-image: url(./folderOpen24-min.png);
}
.folderOpen32 {
  background-image: url(./folderOpen32-min.png);
}
.folderOptions16 {
  background-image: url(./folderOptions16-min.png);
}
.folderProgram16 {
  background-image: url(./folderProgram16-min.png);
}
.folderProgram24 {
  background-image: url(./folderProgram24-min.png);
}
.help24 {
  background-image: url(./help24-min.png);
}
.htmlFile16 {
  background-image: url(./htmlFile16-min.png);
}
.htmlFile32 {
  background-image: url(./htmlFile32-min.png);
}
.imaging16 {
  background-image: url(./imaging16-min.png);
}
.internetExplorere16 {
  background-image: url(./internetExplorer16-min.png);
}
.internetExplorere32 {
  background-image: url(./internetExplorer32-min.png);
}
.logOff24 {
  background-image: url(./logOff24-min.png);
}
.msDos16 {
  background-image: url(./msDos16-min.png);
}
.notepad16 {
  background-image: url(./notepad16-min.png);
}
.notepadFile16 {
  background-image: url(./notepadFile16-min.png);
}
.notepadFile32 {
  background-image: url(./notepadFile32-min.png);
}
.outlook16 {
  background-image: url(./outlook16-min.png);
}
.outlook32 {
  background-image: url(./outlook32-min.png);
}
.paint16 {
  background-image: url(./paint16-min.png);
}
.paint32 {
  background-image: url(./paint32-min.png);
}
.recycle32 {
  background-image: url(./recycle32-min.png);
}
.run24 {
  background-image: url(./run24-min.png);
}
.scheduler16 {
  background-image: url(./scheduler16-min.png);
}
.settings24 {
  background-image: url(./settings24-min.png);
}
.settingsPrinters16 {
  background-image: url(./settingsPrinters16-min.png);
}
.settingsTaskbar16 {
  background-image: url(./settingsTaskbar16-min.png);
}
.shutDown24 {
  background-image: url(./shutDown24-min.png);
}
.windowsExplorer16 {
  background-image: url(./windowsExplorer16-min.png);
}
.windowsUpdate16 {
  background-image: url(./windowsUpdate16-min.png);
}
.windowsUpdate24 {
  background-image: url(./windowsUpdate24-min.png);
}
.wordpad16 {
  background-image: url(./wordpad16-min.png);
}

.back {
  background-image: url(./back-min.png);
}
.forward {
  background-image: url(./forward-min.png);
}
.cut {
  background-image: url(./cut-min.png);
}
.copy {
  background-image: url(./copy-min.png);
}
.paste {
  background-image: url(./paste-min.png);
}
.delete {
  background-image: url(./delete-min.png);
}
.properties {
  background-image: url(./properties-min.png);
}
.undo {
  background-image: url(./undo-min.png);
}
.upDir {
  background-image: url(./upDir-min.png);
}
.views {
  background-image: url(./views-min.png);
}

.ieFavorites {
  background-image: url(./ie-favorites-min.png);
}
.ieGo {
  background-image: url(./ie-go-min.png);
}
.ieHistory {
  background-image: url(./ie-history-min.png);
}
.ieMail {
  background-image: url(./ie-mail-min.png);
}
.ieHome {
  background-image: url(./ie-home-min.png);
}
.ieRefresh {
  background-image: url(./ie-refresh-min.png);
}
.iePrint {
  background-image: url(./ie-print-min.png);
}
.ieSearch {
  background-image: url(./ie-search-min.png);
}
.ieStop {
  background-image: url(./ie-stop-min.png);
}

.minesweeper {
  background-image: url(./minesweeper16-min.png);
}
.skifree {
  background-image: url(./skifree-min.png);
}
