@import "../../../node_modules/packard-belle/src/_scss/w98/mixins/box-shadows.scss";

@font-face {
  font-family: "FixedSys";
  src: url("../../assets/FixedsysStripped.woff2") format("woff2");
}

.Window--blocked * {
  pointer-events: none !important;
  user-select: none;
}

.Notepad {
  &__textarea {
    @include shadow-input;
    padding: 2px 2px 3px 1px;
    overflow: hidden;
    background-color: white;
    display: flex;
    flex-grow: 1;
    margin-bottom: 2px;
  }
  textarea {
    font-family: "FixedSys", "Courier New", Courier, monospace;
    resize: none;
    white-space: nowrap;
    outline: none;
    min-height: 100%;
    border: none;
    flex-grow: 1;
  }
  &--wrap {
    textarea {
      width: 100%;
      white-space: normal;
    }
  }
}
