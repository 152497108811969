.IframeWindow {
  > div:not(.Window__heading) {
    width: 100%;
    height: calc(100% - 10px);
  }
  iframe {
    border: none;
    width: 100%;
    height: calc(100% - 6px);
  }

  &--alert.WindowAlert {
    position: absolute;
    left: 50%;
    top: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 8;
  }
}

.react-draggable-dragging {
  .IframeWindow {
    > div,
    iframe {
      pointer-events: none;
    }
  }
}
